import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const logo = 'https://ik.imagekit.io/gunadev/BrandFuzz/Logos/tbf_logo.png?updatedAt=1724313237451';

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleNavClick = (sectionId) => {
    setActiveSection(sectionId);
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
  };

  const controlHeader = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > lastScrollY && window.scrollY > 100) {
        // Scrolling down and past a threshold
        setIsHeaderVisible(false);
      } else {
        // Scrolling up
        setIsHeaderVisible(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlHeader);

      return () => {
        window.removeEventListener('scroll', controlHeader);
      };
    }
  }, [lastScrollY]);

  return (
    <div
      className={classNames(
        'bg-[#121212] font-sans cust__container m-0 border-b-[1px] border-black/20 z-[9999] sticky-header',
        {
          'header-hidden': !isHeaderVisible,
        }
      )}
    >
      <div className="shadow">
        <div className="mx-auto">
          <div className="flex items-center sm:justify-between py-4">
            <div className="flex-1 sm:flex-none">
              <img src={logo} className="w-[12em]" alt="Brandfuzz Logo" />
            </div>

            <div className="hidden sm:flex gap-8 sm:items-center text-[17px]">
              {['achievements', 'testimonials', 'CRO List', 'FAQ'].map((section) => (
                <a
                  key={section}
                  href={`#${section}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavClick(section);
                  }}
                  className={classNames(
                    'text-white hover:text-neutral-100 mr-4 relative link-underline',
                    {
                      'text-white': activeSection === section, // Change text color to white if active
                      'active': activeSection === section,
                    }
                  )}
                >
                  {section.charAt(0).toUpperCase() + section.slice(1)}
                </a>
              ))}
            </div>

            <div className="flex sm:items-center px-4 sm:px-0">
              <a
                href="https://calendly.com/manik-thebrandfuzz/30min"
                className="text-white flex gap-2 items-center text-sm md:text-[0.8rem] lg:text-[1rem] px-6 lg:px-8 py-2 lg:py-4 rounded-full bgg-gradient thing"
              >
                <svg
                  className="w-8 text-white pr-1 border-r-[1px] border-white"
                  viewBox="0 0 256 256"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect fill="none" height="256" width="256" />
                  <path
                    d="M159.4,40A80.1,80.1,0,0,1,216,96.6"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="12"
                  />
                  <path
                    d="M151.1,70.9a47.9,47.9,0,0,1,34,34"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="12"
                  />
                  <path
                    d="M92.5,124.8a83.6,83.6,0,0,0,39,38.9,8,8,0,0,0,7.9-.6l25-16.7a7.9,7.9,0,0,1,7.6-.7l46.8,20.1a7.9,7.9,0,0,1,4.8,8.3A48,48,0,0,1,176,216,136,136,0,0,1,40,80,48,48,0,0,1,81.9,32.4a7.9,7.9,0,0,1,8.3,4.8l20.1,46.9a8,8,0,0,1-.6,7.5L93,117A8,8,0,0,0,92.5,124.8Z"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="12"
                  />
                </svg>
                Book a call
              </a>
            </div>
            <div
              className={classNames(
                'sm:hidden cursor-pointer transform transition-transform duration-300',
                {
                  'rotate-90': isMobileMenuOpen,
                }
              )}
              onClick={toggleMobileMenu}
            >
              {isMobileMenuOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-white"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M19.707 5.707a1 1 0 0 0-1.414-1.414L12 10.586 5.707 4.293a1 1 0 0 0-1.414 1.414L10.586 12l-6.293 6.293a1 1 0 1 0 1.414 1.414L12 13.414l6.293 6.293a1 1 0 0 0 1.414-1.414L13.414 12l6.293-6.293z"
                  />
                </svg>
              ) : (
                <svg
                  className="w-6 h-6"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title />
                  <g data-name="menu " id="menu_">
                    <path
                      d="M29,6H3A1,1,0,0,0,3,8H29a1,1,0,0,0,0-2Z"
                      fill="#fff"
                    />
                    <path
                      d="M3,17H16a1,1,0,0,0,0-2H3a1,1,0,0,0,0,2Z"
                      fill="#fff"
                    />
                    <path
                      d="M25,24H3a1,1,0,0,0,0,2H25a1,1,0,0,0,0-2Z"
                      fill="#fff"
                    />
                  </g>
                </svg>
              )}
            </div>
          </div>

          <div
            className={classNames(
              'sm:hidden bg-[#121212] overflow-hidden transition-all duration-500 ease-in-out',
              {
                'max-h-screen': isMobileMenuOpen,
                'max-h-0': !isMobileMenuOpen,
              }
            )}
          >
            <div className="flex flex-col sm:items-center text-sm md:text-md lg:text-lg py-2">
              {['results', 'testimonials', 'features', 'process'].map((section) => (
                <a
                  key={section}
                  href={`#${section}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavClick(section);
                  }}
                  className="text-neutral-400 hover:text-neutral-100 mb-1"
                >
                  {section.charAt(0).toUpperCase() + section.slice(1)}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
