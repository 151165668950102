import React from "react";
import '../assets/css/footer.css';

function Footer() {

  return (
    <section
      id="footer"
      className="cust_container relative flex flex-col justify-center bg-black py-[10em] sm:py-[14em] h-screen overflow-hidden z-[999]"
    >
        <div className="absolute bottom-[1em] w-full flex flex-col justify-center items-center">
        <div className="policies__wrapper flex items-center gap-2 my-2 text-[14px] xl:text-[16px]">
          <a href="https://thebrandfuzz.in/privacy.html" target="_blank" rel="noopener noreferrer"><label className="hover:underline text-white/50 hover:text-white cursor-pointer">Privacy Policy</label></a>
          <a href="https://thebrandfuzz.in/terms.html" target="_blank" rel="noopener noreferrer"><label className="hover:underline text-white/50 hover:text-white cursor-pointer">Terms of Service</label></a>
        </div>
        <label className="text-[14px] xl:text-[16px] text-white">©2025 The Brand Fuzz. All Rights Reserved</label>
        </div>
      <div className="w-full flex justify-center">
        <div className="xl:max-w-[50%] flex flex-col justify-center items-center gap-[2em] text-center">
        <label className='uppercase text-neutral-400 font-medium border border-neutral-600 rounded-full px-[1em] py-[.4em] text-[14px] sm:text-[16px]'>Let your "Brand fuzz" around</label>
          <h2 className="footer__headline px-[.2em] md:px-0 font-semibold text-[50px] md:text-[70px] xl:text-[90px] text-neutral-200 leading-tight">
            Skyrocket your sales to <em className="">new levels!</em>
          </h2>
          <p className="text-white/60 text-[16px] sm:text-[18px] max-w-[80%] md:max-w-[60%]">
          Remember: You only pay when we deliver profitable results, eliminating any risk. Unlike
other agencies, we guarantee this because we consistently deliver profitable results at
scale.
          </p>
          <a
                href="https://calendly.com/manik-thebrandfuzz/30min"
                className="text-white flex gap-2 w-[16em] md:w-[15em] items-center text-[14px] sm:text-[16px] xl:text-[18px] px-[3em] py-4 xl:py-6 rounded-full uppercase font-semibold bgg-gradient thing"
              >
                <svg
                  className="w-8 text-white"
                  viewBox="0 0 256 256"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect fill="none" height="256" width="256" />
                  <path
                    d="M159.4,40A80.1,80.1,0,0,1,216,96.6"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="12"
                  />
                  <path
                    d="M151.1,70.9a47.9,47.9,0,0,1,34,34"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="12"
                  />
                  <path
                    d="M92.5,124.8a83.6,83.6,0,0,0,39,38.9,8,8,0,0,0,7.9-.6l25-16.7a7.9,7.9,0,0,1,7.6-.7l46.8,20.1a7.9,7.9,0,0,1,4.8,8.3A48,48,0,0,1,176,216A136,136,0,0,1,40,80,48,48,0,0,1,81.9,32.4a7.9,7.9,0,0,1,8.3,4.8l20.1,46.9a8,8,0,0,1-.6,7.5L93,117A8,8,0,0,0,92.5,124.8Z"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="12"
                  />
                </svg>
                Book a call
              </a>
        </div>
      </div>
    </section>
  );
}

export default Footer;
