import '../assets/css/featuredwork.css';
import mockups from '../data/mockups.json';

function FeaturedWork() {

  return (
    <section className="cust__work-container relative flex justify-center items-center bg-gray-100 bg-opacity-30 pt-36">
      <div className="absolute right-10 md:right-20 " style={{ top: "-40px" }}>
        <div className="relative w-24 md:w-32">
          <img
            className="absolute top-0 w-24 md:w-32 animate-rotate360"
            src="https://ik.imagekit.io/gunadev/BrandFuzz/Badges/brandfuzz-badge.png?updatedAt=1736950801584"
            alt=""
          />
          <img
            className="absolute top-0 w-24 md:w-32"
            src="https://ik.imagekit.io/gunadev/BrandFuzz/Badges/tbf_icon_black.png?updatedAt=1724312006212"
            alt=""
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-7 justify-center max-w-full relative pb-10 md:pb-36">
        <div className="w-full col-span-4 flex flex-col items-center gap-20 relative">
          {mockups.map((mockup, index) => (
            <img
              key={index}
              className="w-[90%] md:w-[80%] sticky top-20"
              src={mockup.image}
              alt={mockup.title}
            />
          ))}
        </div>
        <div className="w-full col-span-3 flex flex-col gap-4 pl-0 md:pl-4 text-neutral-400 relative mt-10 md:mt-0 items-start">
          <div className="w-full md:w-[90%] bg-tansparent sticky top-20 min-h-[450px]  rounded-lg p-4">
            <h2 className="font-semibold text-center md:text-left text-[36px] sm:text-[47px] xl:text-[55px] text-neutral-800 leading-tight mb-8">Our<span className="text-gradient">&nbsp;Recent Work </span> </h2>
            <p className="text-neutral-800  mb-2">Do you know what they love</p>
            <ul className="text-neutral-800">
              <li className="mb-2 list__item">High Conversion Rate</li>
              <li className="mb-2 list__item">Crisp and Clean Layout </li>
              <li className="mb-2 list__item">Responsive Design</li>
              <li className="mb-2 list__item">Speed and Performance</li>
              <li className="mb-2 list__item">SEO-Friendly</li>
              <li className="mb-2 list__item">Custom Features</li>
              <li className="mb-2 list__item">User-Centric Design</li>
              <li className="mb-2 list__item">Innovative Design Techniques</li>
            </ul>
            <div className="w-full flex justify-center md:justify-start">
            <a
                href="https://calendly.com/manik-thebrandfuzz/30min"
                className="text-white w-[14em] flex gap-2 items-center justify-center mt-8 text-sm md:text-[0.8rem] lg:text-[1rem] px-8 py-5 rounded-full bgg-gradient thing"
              >
                <svg
                  className="w-8 text-white pr-1 border-r-[1px] border-white"
                  viewBox="0 0 256 256"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect fill="none" height="256" width="256" />
                  <path
                    d="M159.4,40A80.1,80.1,0,0,1,216,96.6"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="12"
                  />
                  <path
                    d="M151.1,70.9a47.9,47.9,0,0,1,34,34"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="12"
                  />
                  <path
                    d="M92.5,124.8a83.6,83.6,0,0,0,39,38.9,8,8,0,0,0,7.9-.6l25-16.7a7.9,7.9,0,0,1,7.6-.7l46.8,20.1a7.9,7.9,0,0,1,4.8,8.3A48,48,0,0,1,176,216A136,136,0,0,1,40,80,48,48,0,0,1,81.9,32.4a7.9,7.9,0,0,1,8.3,4.8l20.1,46.9a8,8,0,0,1-.6,7.5L93,117A8,8,0,0,0,92.5,124.8Z"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="12"
                  />
                </svg>
                <span className='uppercase'>Book a call</span>
              </a>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturedWork;
